import React from "react"
import styled from "styled-components"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"

import useWindowSize from "utils/useWindowSize"

import ServicesTemplate from "templates/ServicesTemplate"

import InformationText from "components/InformationText/InformationText"
import Button from "components/Button/Button"
import PicturesCarousel from "components/PicturesCarousel/PicturesCarousel"

const StyledContent = styled.div`
  width: 100%;
  height: auto;
  min-height: 100%;
  background-color: white;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  min-height: 480px;
  padding: 3rem;
  box-shadow: 3px 10px 103px -52px rgba(107, 107, 107, 1);

  @media (max-width: 1200px) {
    padding: 2rem;
  }

  @media (max-width: 600px) {
    padding: 0;
    background-color: transparent;
    box-shadow: none;
    justify-content: flex-start;
  }
`

const StyledContentPart = styled.div`
  width: 50%;
  height: 100%;

  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media (max-width: 1200px) {
    width: 100%;
    height: auto;
    align-items: center;

    p {
      text-align: center;

      @media (max-width: 600px) {
        text-align: left;
        align-items: flex-start;
        align-self: flex-start;
      }
    }
  }

  @media (max-width: 600px) {
    justify-content: flex-start;
    align-self: flex-start;
  }
`

const StyledTitle = styled(InformationText)`
  color: ${({ theme }) => theme.colors.services.textBlack};
  text-transform: uppercase;

  @media (min-width: 1750px) {
    font-size: ${({ theme }) => `calc(${theme.fontSize.xxxs} + 0.3vmax)`};
  }
`

const StyledInformationText = styled(InformationText)`
  line-height: 15px;
  color: ${({ theme }) => theme.colors.services.descriptionText};
  @media (min-width: 1200px) {
    padding-right: 1.5rem;
  }

  @media (min-width: 1750px) {
    font-size: ${({ theme }) => `calc(${theme.fontSize.xxxs} + 0.2vmax)`};
  }

  @media (max-width: 600px) {
    margin: 8px 0;
  }
`

const StyledClickBelowText = styled(StyledInformationText)`
  font-size: ${({ theme }) => theme.fontSize.xxxxxs};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: black;
  margin-bottom: 16px;

  @media (max-width: 600px) {
    font-size: ${({ theme }) => theme.fontSize.xxxxs};
  }

  @media (min-width: 1750px) {
    font-size: ${({ theme }) => `calc(${theme.fontSize.xxxxs} + 0.1vmax)`};
  }
`

const StyledCarouselContainer = styled.div`
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1200px) {
    width: 100%;
    height: auto;
    margin: 16px 0;
  }

  @media (max-width: 600px) {
    padding: 0;
    margin: 32px 0;
  }
`

function Lines() {
  const size = useWindowSize()
  const intl = useIntl()

  return (
    <ServicesTemplate  withKredobank>
      <>
        <StyledContent>
          <StyledContentPart>
            {size.width > 600 && (
              <StyledTitle huge bold>
                <FormattedMessage id="services.linesTitle" />
              </StyledTitle>
            )}
            <StyledInformationText>
              <FormattedMessage id="services.linesDescription" />
            </StyledInformationText>
            <StyledClickBelowText>
              <FormattedMessage id="services.checkBelow" />
            </StyledClickBelowText>
            <Button
              path="/contact/"
              name={intl.formatMessage({ id: "services.button" })}
            />
          </StyledContentPart>
          <StyledCarouselContainer>
            <PicturesCarousel />
          </StyledCarouselContainer>
        </StyledContent>
      </>
    </ServicesTemplate>
  )
}

export default Lines
