import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { FormattedMessage } from "gatsby-plugin-intl"

import InformationTitle from "components/InformationTitle/InformationTitle"

import Layout from "layout/Layout"

import KredobankLogo from "assets/kredobank-logo.svg"

const StyledWrapper = styled.div`
  width: 100vw;
  min-height: 100vh;
  background: white;
  display: flex;
  flex-direction: column;
  padding-left: 5vw;
  padding-right: 5vw;
  max-width:1680px;
  margin-inline:auto;
`
const StyledInformationContainer = styled.div`
  margin-top: 200px;
  margin-bottom: 16px;

  @media (max-width: 700px) {
    margin-top: 150px;
  }
`

const StyledInformation = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 885px) {
    flex-wrap: wrap;
  }
`

const StyledInformationTitle = styled(InformationTitle)`
  color: ${({ theme }) => theme.colors.contact.contactTitle};
  font-size: ${({ theme }) => theme.fontSize.xxxs};
  width: auto;
  text-transform: uppercase;
  &:before {
    background-color: ${({ theme }) => theme.colors.contact.contactTitle};
  }
`

const StyledKredobankLogo = styled(KredobankLogo)`
  width: auto;
  height: 50px;
  align-self: flex-start;
  margin-left: 16px;

  @media (max-width: 885px) {
    margin-left: 0;
  }
`

const ServicesTemplate = ({ children, titleID, withKredobank }) => {
  return (
    <Layout>
      <>
        <StyledWrapper>
          {/* <StyledInformationContainer>
            <StyledInformation>
              <StyledInformationTitle>
                <FormattedMessage id={titleID} />
              </StyledInformationTitle>
              {withKredobank && (
                <>
                  <a
                    href="http://kredobank.com.ua/business/malomu-ta-serednomu-biznesu/agrobiznes/kredytna-liniya-agro"
                    target="_blank"
                    style={{
                      cursor: "pointer",
                    }}
                    rel="noreferrer"
                  >
                    <StyledKredobankLogo />
                  </a>
                </>
              )}
            </StyledInformation>
          </StyledInformationContainer> */}
          {children}
        </StyledWrapper>
      </>
    </Layout>
  )
}

ServicesTemplate.propTypes = {
  children: PropTypes.node.isRequired,
  titleID: PropTypes.string.isRequired,
  banner: PropTypes.bool,
  withKredobank: PropTypes.bool,
}

export default ServicesTemplate
